import '/assets/styles/pages/product-detail.scss';

import { useState, useEffect, useMemo, lazy } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase'
import loadable from '@loadable/component';

// Functions
import getCdnImage from '/functions/get-cdn-image'
import formatDate from 'pstv-commerce-tools/functions/format-date';

// Services
import productServices from '/services/product';

// Context
import { useBasketProductControls } from '/controllers/basket';
import { useParams } from 'pstv-commerce-tools/utilities/navigator'
import { useHead } from 'pstv-commerce-tools/utilities/head';
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';
import { useGlobalEvents } from 'pstv-commerce-tools/utilities/global-events'
import { useModals } from 'pstv-commerce-tools/utilities/modals';

// Partials
import Placeholder from '/views/partials/placeholder'
import Pricebox from '/views/partials/pricebox';
import Btn from '/views/partials/btn';
import Collapser from '/views/partials/collapser';
import Icon from 'pstv-commerce-tools/partials/icon';
import Img from 'pstv-commerce-tools/partials/img';
import LazyComponent from 'pstv-commerce-tools/partials/lazy-component';
import EmptyPlaceholder from 'pstv-commerce-tools/partials/empty-placeholder';
import NewBadge from '../partials/new-badge';

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs';

// Async Sections
const LazyRelatedProducts = lazy(() => (import('/views/sections/product-relatedproducts')));
const WishlistButton = loadable(() => import('/views/partials/wishlist-button'), { ssr: false })

const getProduct = (productId) => {
	return productServices.getProduct(productId)
}

const parseHead = (productData) => {
	const title = productData.seo_title ?? (productData.product_name + ((productData.serial_title && productData.serial_title.length > 0) ? ` ${productData.serial_title}` : ''));
	return {
		title: title,
	}
}

const ProductDetail = ({ pageProps }) => {
	const { setError, setNotFound } = useGlobalState();
	const { triggerEvent } = useGlobalEvents();
	const { desktop } = useBreakpoints();
	const { setHead } = useHead();
	const { openModal } = useModals();
	const { id: productId } = useParams('productDetail');

	const [{product, fetchedId}, setProduct] = useState(pageProps?.productData ?? {product: null, fetchedId: null});
	// const basketProduct = useBasketProduct(product?.serial_id);

	const computeCampaignInfo = (product) => {
		if(product.campaign) {
			let priceDataOverride = undefined;
			let campaignBoxData = false;

			if(product.campaign.typeid === 101) {
				campaignBoxData = {
					title: product.campaign.name,
					price: product.campaign.price,
				};
			}
			else if(product.campaign.typeid === 102) {
				priceDataOverride = {
					price: product.campaign.price,
					oldPrice: product.campaign.old_price,
				}
			}
			else if(product.campaign.typeid === 103) {
				priceDataOverride = {
					price: product.campaign.price,
				}
			}
			else if(product.campaign.typeid === 104) {
				campaignBoxData = {
					title: product.slogan,
				}
			}

			return {
				priceDataOverride: priceDataOverride,
				campaignBoxData,
				priceDiffData: product.campaign.typeid === 103 ? {
					title: product.campaign.name,
					price: product.campaign.old_price,
				} : false,
				details: product.campaign ? {
					dateInterval: (!!product.campaign.startdate && !!product.campaign.enddate) ? `${formatDate(product.campaign.startdate)} - ${formatDate(product.campaign.enddate)}` : false,
					stock: `${product.campaign.campaign_stock} ${product.unit?.title ?? 'Adet'}`,
					description: product.campaign.description,
				} : false,
			}
		}
		else {
			return false;
		}
	}

	const { productBrand, mainCategory, productUnit, priceParams, productImages, unitSizeData, productTitle, campaignInfo } = useMemo(() => {
		if(product) {
			const getProductCdnImage = (size) => {
				return getCdnImage((product?.images?.length > 0 ? product.images[0].url : false), { width: size, height: size, bgColor: 'white', quality: 50 })
			}

			const campaignInfo = computeCampaignInfo(product);
	
			return {
				productBrand: (product.brand_id && product.brand) ? {
					name: product.brand,
					params: {
						id: product.brand_id,
						slug: kebabCase(product.brand ?? 'Marka'),
					}
				} : null,
				mainCategory: product.category_breadcrumb ? product.category_breadcrumb[product.category_breadcrumb.length -1] : null,
				priceParams: campaignInfo?.priceDataOverride ?? { product: product },
				productImages: {
					web: getProductCdnImage(360),
					web2x: getProductCdnImage(720),
					mobile: getProductCdnImage(594),
				},
				unitSizeData: (product?.sale_unit_type === 'PiecesChangeableWeight' && product.basket_unit_weight) ? {
					unitWeightText: `1 Adet / Ortalama ${product.basket_unit_weight} KG`,
				} : false,
				productTitle: product.product_name + (product.serial_title?.length ? ` ${product.serial_title}` : ''),
				campaignInfo,
				productUnit: (product.sale_unit_type === 'PiecesChangeableWeight' && product.basket_unit_weight) ? 'KG' : (product.unit?.title !== 'Adet' ? product.unit?.title : false),
			}
		}
		else {
			return {}
		}
	}, [product])

	useEffect(() => {
		triggerEvent('productView', { product });
	}, [product])

	useEffect(() => {
		if(productId && fetchedId !== productId) {
			setProduct({ product: null, fetchedId: productId });
			getProduct(productId).then(productData => {
				setProduct({ product: productData, fetchedId: productId});
				setHead(parseHead(productData));
			}).catch((error) => {
				if(error.status === 404) {
					setNotFound();
				}
				else {
					setError();
				}
			})
		}
	}, [productId, fetchedId])

	return (
		<>
			<Breadcrumbs data={product?.category_breadcrumb} />
			<div className="wrapper product-detail-wrap">
				<main className="product-detail-main">
					<div className="main-left">
						<WishlistButton className="left-favbtn" product={product} />
						<button
							onClick={() => {
								if(product) {
									openModal('productGallery', { product: product });
								}
							}}
							className="left-gallery">
							{product ?
								<>
									<Img
										lazy={false}
										loading="eager"
										srcSet={desktop ? `${productImages.web2x} 2x` : undefined}
										className="gallery-image"
										src={desktop ? productImages.web : productImages.mobile} />
									<Icon name="zoom" className="gallery-zoomicon" />
								</>
								:
								<Placeholder className="gallery-image" />
							}
						</button>

						{(mainCategory || productBrand) &&
							<div className="left-relatedlinks">
								{productBrand &&
									<Btn
										tag="link"
										href="brand"
										params={productBrand.params}
										wrapText
										className="relatedlinks-link outline"
										afterIcon="arrow-right">
										{productBrand.name} Markalı Ürünler
									</Btn>
								}
								{mainCategory &&
									<Btn
										tag="link"
										href={mainCategory.href}
										params={mainCategory.params}
										wrapText
										className="relatedlinks-link outline"
										afterIcon="arrow-right">
										Tüm &quot;{mainCategory.name}&quot; Ürünleri
									</Btn>
								}
							</div>
						}
					</div>
					<div className="main-right">
						{product ?
							<>
								{product.new_product &&
									<NewBadge className="right-newbadge" />
								}
								<span className="right-sku">{product.product_erp_code}</span>
								<h1 className="right-title">{productTitle}</h1>

								{(product.slogan && product.slogan.length > 0) &&
									<span className="right-slogan">{product.slogan}</span>
								}

								<div className="right-features">
									<Collapser
										wysiwyg
										open={desktop}
										className="features-feature"
										html={product.description.length > 0 ? product.description : 'Ürün açıklaması bulunmuyor...'}
										title="Ürün Açıklaması">
									</Collapser>
									{(product.madein && product.madein.length > 0) &&
										<Collapser
											wysiwyg
											className="features-feature"
											title="Menşei"
											html={product.madein}>
										</Collapser>
									}
									{(product.nutritional_value && product.nutritional_value.length > 0) &&
										<Collapser
											wysiwyg
											className="features-feature"
											title="Besin Değerleri"
											html={product.nutritional_value}>
										</Collapser>
									}
									{(product.nutritional_value && product.nutritional_value.length > 0) &&
										<Collapser
											wysiwyg
											className="features-feature"
											title="İçindekiler"
											html={product.contents}>
										</Collapser>
									}
								</div>
							</>
							:
							<>
								<Placeholder className="right-sku" />
								<Placeholder className="right-title" />
								<div className="right-features">
									<Placeholder className="features-feature" />
									<Placeholder className="features-feature" />
								</div>
							</>
						}
					</div>
				</main>
				<aside className="product-detail-aside">
					<div className="aside-info">
						{product ?
							<>
								<div className="info-pricearea">
									<span className="pricearea-title">
										Ürün Fiyatı
									</span>
									<Pricebox
										big
										className="pricearea-price"
										{...priceParams}
										unit={productUnit} />
								</div>

								{campaignInfo.campaignBoxData &&
									<div className="info-campaignbox">
										<strong className="campaignbox-title">{campaignInfo.campaignBoxData.title}</strong>
										{campaignInfo.campaignBoxData.price &&
											<Pricebox
												big
												className="campaignbox-price"
												price={campaignInfo.campaignBoxData.price} />
										}
									</div>
								}

								{campaignInfo.priceDiffData &&
									<div className="info-pricediffbox">
										<strong className="pricediffbox-title">{campaignInfo.priceDiffData.title}</strong>
										<Pricebox
											className="pricediffbox-price"
											price={campaignInfo.priceDiffData.price} />
									</div>
								}

								{(unitSizeData || product.sale_unit_type === 'Weight') &&
									<>
										<div className="info-unitscale">{unitSizeData.unitWeightText}</div>
										<div className="info-message">
											<Icon name="info" />Son fiyat ürün tartıldığında gramaja göre belirlenir.
										</div>
									</>
								}

								<BasketControls product={product} />

								<div className="info-unitprice">
									<strong className="unitprice-title">Birim Fiyat</strong>
									<span>
										<Pricebox className="unitprice-price" price={product.base_unit_price || product.price} />
										{` / ${(product.baspricemeasurement || product.base_unit || product.unit?.title) ?? 'Adet'}`}
									</span>
								</div>
							</>
							:
							<>
								<div className="info-pricearea">
									<Placeholder className="pricearea-title" />
									<Pricebox className="pricearea-price" />
								</div>
								<BasketControls />
								<Placeholder className="info-unitprice" />
							</>
						}
					</div>
					{campaignInfo &&
						<CampaignInfo data={campaignInfo} />
					}
					
				</aside>
			</div>
			<LazyComponent placeholder={
				<EmptyPlaceholder
					styles={{ 'height': '38.3rem' }}
					mobileStyles={{ 'height': '30.4rem' }} />
			}>
				<LazyRelatedProducts
					productId={productId} />
			</LazyComponent>
		</>
	)
}

ProductDetail.propTypes = {
	pageProps: PropTypes.object,
}

const BasketControls = ({ product }) => {
	const { quantity, quantityDisplay, increaseQuantity, decreaseQuantity, busy } = useBasketProductControls(product);

	if(product) {
		return (
			<div className={`info-controls${busy ? ' busy' : ''}`}>
				{quantity === 0 ?
					<>
						{product.quantity === 0 ?
							<button disabled type="button" className="controls-addbtn">
								Ürün Stokta Bulunmamaktadır
							</button>
							:
							<button disabled={busy} type="button" className="controls-addbtn" onClick={increaseQuantity}>
								<Icon name="cart" /> Sepete Ekle
							</button>
						}
					</>
					:
					<>
						<Btn disabled={busy} className="controls-quantitybtn secondary" onClick={decreaseQuantity}>
							<Icon name="minus" />
						</Btn>
						<span className="controls-display">
							<span>
								<strong>{quantityDisplay}</strong> {product.unit?.title || 'Adet'}
							</span>
						</span>
						<Btn disabled={busy} className="controls-quantitybtn secondary" onClick={increaseQuantity}>
							<Icon name="plus" />
						</Btn>
					</>
				}
			</div>
		)
	}
	else {
		return (
			<Placeholder className="info-controls" />
		)
	}
}

BasketControls.propTypes = {
	product: PropTypes.object,
}

const CampaignInfo = ({ data }) => {
	if(data?.details) {
		return (
			<div className="aside-campaign">
				<div className="campaign-description">
					<Icon name="campaign-full" className="description-icon" />
					<div
						className="description-text wysiwyg"
						dangerouslySetInnerHTML={{__html: data.details.description }} />
				</div>
				<strong className="campaign-title">Kampanya Bilgileri</strong>
				{!!data.details.stock &&
					<div className="campaign-info date">
						Kampanya stoğu: <strong>{data.details.stock}</strong>
					</div>
				}
				{data.details.dateInterval &&
					<div className="campaign-info date">
						Kampanya tarih aralığı: <strong>{data.details.dateInterval}</strong>
					</div>
				}
			</div>
		)
	}
	else {
		return null;
	}
}

CampaignInfo.propTypes = {
	data: PropTypes.object,
}

ProductDetail.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		getProduct(match.params.id).then((productData) => {
			resolve({
				pageProps: {
					productData: {
						product: productData,
						fetchedId: match.params.id
					},
				},
				head: parseHead(productData),
			})
		}).catch((error) => {
			const status = error.status ?? 500;
			resolve({
				status: status,
				notFound: status === 404,
				error: status !== 404,
			})
		})
	})
}

export default ProductDetail
